<template>
  <div class="app-board" style="background-color: #f0e2e8" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
    <exit-button style="right: 215px;top: -20px;"></exit-button>

    <el-row>
        <el-table
            :data="tableData"
            :row-class-name="setRowClass"
            :cell-class-name="setCellClass"
            @row-click="loadSelectedClient"
            :header-cell-style="{fontSize: '11pt', padding: '5px 0'}"
            style="width: 100%;height: 495px;overflow-y: scroll;font-size: 9pt;">
          <el-table-column
              label="CLIENTS"
              prop="business_name">
            <template slot-scope="scope">
              {{scope.row.business_name}}
            </template>
          </el-table-column>
        </el-table>
    </el-row>
    <el-row>
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
        <display-filter-section :autoBind="false" :editMode="editMode" @filterUpdated="(p) => {filterUpdated(p)}"></display-filter-section>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import module from './../../index'
export default {
  mixins: [$mixins['listView']],
  components: {
    'exit-button': () => import('@/components/exit-button/View'),
    'display-filter-section': () => import('./DisplayFilterSection'),
  },
  beforeMount() {
    this.currentClientId = $router.currentRoute.params.clientId

  },
  mounted() {
    //this.getEntityListData()
  },
  methods: {
    filterUpdated (p) {
      this.filterData = p
      this.getEntityListData(1)
    },
    getEntityListData (page, onLoaded = false) {
      this.amReady = false
      this.getEntityList({page: 1, ...this.filterData, 'get-all-records': 1}).then(response => {
        this.tableData = response.rows
        this.recordsCounter = response.count
        this.amReady = true
        this.currentPage = page
        if (this.reopenDialogAfterRefresh) {
          this.reopenDialogAfterRefresh = false
          this.dataToBind = response.rows[this.rowToEdit-1]
          this.dialogVisible = true
        }
        if (onLoaded) onLoaded(response)
      })
    },
    setRowClass({row, rowIndex}) {
      if (row.id == this.currentClientId) {
        return ''
      }
      return 'app-main-bg';
    },
    setCellClass({row, rowIndex}) {
      if (row.id == this.currentClientId) {
        return 'custom-data-table-cell-business active-business-cell'
      }
      return 'custom-data-table-cell-business';
    },
    loadSelectedClient (row, column, event) {
      $router.push({name: 'module->Accounting->Client->view', params: {clientId: row.id}})
    },
    ...$mapActions(module.info.fullName + '-api', {getEntityList: 'list', updateEntity: 'update', multipleDelete: "multipleDelete"}),
  },
  computed: {
    ...$mapState(module.info.fullName + '-api', ['pageChunkSize']),
  },
  props: {
    control: {
      required: true
    }
  },
  data () {
    return {
      filterData: {},
      currentClientId: '-1',
      module: {...module},
    }
  }
}
</script>
